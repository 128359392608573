import React from "react";
import { createAuthRemoteCall } from "../../store/utils";
import * as actionTypes from "./action-types";
import { logout } from "../authorization";
import { denormalizeEntitiesArray } from "../../selectors/utils";
import { updateRegisterState } from "../register";
import { convertToDollars } from "../../utils/common";
import { reset } from "redux-form";
import { saveAs } from "file-saver";
import { toast } from 'react-toastify';
import { updateAuctionItem } from "../live-auction";

export const getAuctionItem = (params) => createAuthRemoteCall({ fetch: actionTypes.GET_ITEM, loading: actionTypes.LOADING_ITEM }, (api) => api.auction.getItem, logout)(params);

export const getPhotoGallery = (params) => createAuthRemoteCall({ fetch: actionTypes.GET_PHOTO_GALLERY, loading: actionTypes.LOADING_GALLERY }, (api) => api.auction.getPhotoGallery, logout)(params);

export const getItemInspection = (params) => createAuthRemoteCall({ fetch: actionTypes.GET_ITEM_INSPECTION }, (api) => api.auction.getItemInspection, logout)(params);

export const preBid = (data) => async (dispatch) => {
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.PRE_BID, loading: actionTypes.LOADING_PRE_BID }, (api) => api.auction.preBid, logout)(data));

  if (result !== undefined && !result.error) {
    dispatch(
      updateItemState({
        preBidAmount: convertToDollars(data.amount),
      })
    );

    dispatch(
      updateStateValue({
        bidError: false,
      })
    );

    dispatch(
      updateRegisterState({
        offerInProgress: { hasOffer: false },
      })
    );

    let preBidResult = result.payload.preBidResult;
    switch (preBidResult) {
      case 100:
        dispatch(updateAuctionItemSettings({ highBidder: true, outbid: false }));
        break;
      case 200:
        dispatch(updateAuctionItemSettings({ outbid: true, highBidder: false }));
        break;
      default:
        break;
    }
  } else if (result.error) {
    dispatch(
      updateStateValue({
        validateMessage: result.messages[0],
        bidError: true,
        preBidResult: 300,
      })
    );
    dispatch(reset("bidControlForm"));
    dispatch(
      updateItemState({
        loaded_preBid: true,
      })
    );
    dispatch(
      updateRegisterState({
        offerInProgress: { hasOffer: false },
      })
    );
  }

  return result;
};

export const buyNow = (data) => async (dispatch, callback) => {
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.BUY_NOW }, (api) => api.auction.buyNow, logout)(data));

  if (!result.error || callback) {
    callback();
  }
};



export const updateStateValue = (value) => {
  return {
    type: actionTypes.UPDATE_STATE_VALUE,
    payload: { object: value },
  };
};

export const updateItemMessage = (value) => {
  return {
    type: actionTypes.UPDATE_ITEM_MESSAGE,
    payload: value,
  };
};

export const resetState = () => {
  return {
    type: actionTypes.RESET_STATE,
  };
};


export const updateDocumentSpinnerState = (value) => {
  return {
    type: actionTypes.UPDATE_DOCUMENT_SPINNER_STATE,
    payload: { object: value },
  };
};

export const updateStateOffer = (value, callback) => {
  if (callback) {
    callback();
  }
  return {
    type: actionTypes.UPDATE_STATE_OFFER,
    payload: { object: value },
  };
};

export const makeOffer = (data) => async (dispatch, getState) => {
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.MAKE_OFFER, loading: actionTypes.LOADING_OFFER }, (api) => api.auction.makeOffer, logout)(data));

  if (!result.error) {
    dispatch(
      updateRegisterState({
        offerInProgress: { hasOffer: false },
      })
    );
    dispatch(
      updateItemState({
        offerError: false,
      })
    );
  } else {
    dispatch(
      updateItemState({
        offerErrorMessage: result.messages[0],
        offerError: true,
      })
    );
    dispatch(updateStateOffer({ loaded_offer: true }));
    dispatch(reset("bidControlForm"));
  }
  return result;
};

export const updateAuctionItemSettings = (item) => ({
  type: actionTypes.UPDATE_AUCTION_ITEM_SETTINGS,
  payload: { object: item },
});

export const updateAuctionItemBiddersState = (item) => ({
  type: actionTypes.UPDATE_AUCTION_BIDDERS_STATE,
  payload: item,
});

export const updateAuctionItemViewersState = (item) => ({
  type: actionTypes.UPDATE_AUCTION_VIEWERS_STATE,
  payload: item,
});

export const updateAuctionItemRevisitersState = (item) => ({
  type: actionTypes.UPDATE_AUCTION_REVISITERS_STATE,
  payload: item,
});

export const updateItemState = (item) => ({
  type: actionTypes.UPDATE_ITEM_STATE,
  payload: { object: item },
});

export const getLiveAuctionItem = (item) => {
  return async (dispatch, getState) => {
    const {
      liveAuction: {
        event: { items },
      },
    } = getState();
    const obj = denormalizeEntitiesArray(items);

    for (let i = 0; i < obj.length; i++) {
      if (item === obj[i].item_ID) {
        const params = {
          id: obj[i].id,
          item_ID: obj[i].item_ID,
          endTime: obj[i].endTime,
          bidIncrement: obj[i].bidIncrement,
          startingBid: obj[i].startingBid,
          currentPreBid: obj[i].currentPreBid,
          currentBid: obj[i].currentBid,
          currentBidder: obj[i].currentBidder,
          currentBidder_ID: obj[i].currentBidder_ID,
          currentBidder_ConnectionId: obj[i].currentBidder_ConnectionId,
          outbid: obj[i].outbid,
          totalBids: obj[i].totalBids,
          isActive: obj[i].isActive,
          isSold: obj[i].isSold,
          timer: obj[i].timer,
          going: obj[i].going,
          position: obj[i].position,
          maxBidAmount: obj[i].maxBidAmount,
        };
        dispatch({
          type: actionTypes.GET_LIVE_AUCTION_ITEM,
          payload: params,
        });
      }
    }
  };
};

export const updateInstabidToggle = (item, callback) => {
  if (callback) {
    callback();
  }
  return {
    type: actionTypes.UPDATE_INSTA_BID_TOGGLE,
    payload: { object: item },
  };
};

export const getItemInspectionReport = (params) => async (dispatch) => {
  await dispatch(updateStateValue({ gettingInspectionReport: true }));
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.GET_ITEM_INSPECTION_REPORT }, (api) => api.auction.getItemInspectionReport, logout, true)(params));
  if (!result.error) {
    await saveAs(result.payload, `Inspection Report #${params.Item_ID}`);
  }
  return result;
};

export const getInspectionReport = (params) => async (dispatch) => {
  // await dispatch(updateStateValue({ gettingInspectionReport: true }));
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.GET_INSPECTION_REPORT }, (api) => api.auction.getInspectionReport, logout, true)(params));
  if (!result.error) {
    await saveAs(result.payload, `Inspection Report #${params.Item_ID}`);
  }
  return result;
};

export const loadingEventRegister = () => ({
  type: actionTypes.LOADING_EVENT_REGISTER,
});

export const getHandoffToken = (data) => async (dispatch, callback) => {
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.GET_HANDOFF_TOKEN }, (api) => api.auction.getHandoffToken, logout)(data));
  return result.payload;
};

export const updateUserTermsAcceptance = (data) => async (dispatch, callback) => {
  const result = await dispatch(createAuthRemoteCall({ fetch: actionTypes.UPDATE_USER_TERMS_ACCEPTANCE }, (api) => api.auction.updateUserTermsAcceptance, logout)(data));

  if (result.error) {
    toast.error(`${result.messages[0]}`, {
      position: "top-right",
      auctoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    });
    return result
  }
  return result.payload;
};

export const getTermsAndConditions = (data) => async (dispatch) =>
  await dispatch(createAuthRemoteCall({ fetch: actionTypes.GET_TERMS_AND_CONDITIONS }, (api) => api.auction.getTermsAndConditions, logout)(data));